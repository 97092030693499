import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import axios from 'src/axios';

import { Box, Button, MenuItem, Select, TextField } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackContext, AppContext, ThemeContext } from 'src/store/ContextStore';
import TextInput from 'src/components/UI/input/TextInput';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { getConsentTags, handleUpload } from './formService';
import DateInput from '../../../UI/input/DateInput';

export default function TestForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { theme } = useContext(ThemeContext);

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        testName: '',
        testType: '',
        lab: '',
        dateRequested: new Date(),
        resultDueDate: null,
        dateResultReceived: null,
        testResult: '',
        testNotes: '',
        fileAttachments: [],
        associatedConsent: ''
      };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([
    'Self',
    'GP',
    'Hospital',
    'Private Provider'
  ]);

  let [consentOptions, setConsentOptions] = React.useState(['loading...']);
  React.useEffect(() => {
    const fetchData = async () => {
      const consentTags = await getConsentTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setConsentOptions(consentTags);
    };
    if (consentOptions != [] && consentOptions[0] == 'loading...') fetchData();
  }, []);
  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };
  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.test, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Test Name</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="testName"
              value={formValues.testName}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Test Type"
              type="autocomplete"
              name="testType"
              value={formValues.testType || null}
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  testType: value.label
                });
              }}
              options={typeOptions || []}
              helperText="Please select or enter a test type"
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Date Requested</FormLabel>
          </Grid>
          <DateInput
            name="dateRequested"
            value={formValues.dateRequested}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                referralDate: e
              }))
            }
            variant="outline"
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Consent</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="associatedConsent"
                value={formValues.associatedConsent}
                onChange={handleChange}
              >
                {consentOptions.map((option) => (
                  <MenuItem value={option} key={option} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Result Due Date</FormLabel>
          </Grid>
          <DateInput
            value={
              formValues.resultDueDate
                ? new Date(formatDate(formValues.resultDueDate))
                : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                referralDate: e
              }))
            }
            variant="outline"
            minDate={formValues.dateRequested}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Date Result Received</FormLabel>
          </Grid>
          <DateInput
            value={formValues.dateResultReceived}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                referralDate: e
              }))
            }
            variant="outline"
            minDate={formValues.dateRequested}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Lab</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="lab"
              value={formValues.lab}
              multiline={true}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Test Result</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="testResult"
              value={formValues.testResult}
              multiline={true}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Test Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="testNotes"
              value={formValues.testNotes}
              multiline={true}
            />
          </Box>
        </Grid>

        <Grid item xs={7} style={{ paddingTop: '1.5rem' }}>
          <FormLabel>File Attachments </FormLabel>
        </Grid>
        <Grid item xs={5}>
          <Button variant="contained" color="secondary" component="label">
            Upload Attachment
            <input
              accept="image/*,.pdf,.txt"
              hidden
              type="file"
              onChange={handleFile}
            />
          </Button>
          <Stack spacing={1} style={{ paddingTop: '1rem' }}>
            {formValues.fileAttachments.map((attachment, i) => (
              <Chip
                key={i}
                label={attachment}
                variant="outlined"
                onDelete={() => handleDelete(i)}
              />
            ))}
          </Stack>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          addLabel={`Add new test details`}
        />
      </Grid>
    </form>
  );
}
