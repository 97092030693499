import React from 'react';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { amber } from '@mui/material/colors';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, appearance, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.title}>
        {appearance === 'warning' && (
          <WarningIcon fontSize="small" style={{ color: amber[500] }} />
        )}
        <Typography variant="h6">{children}</Typography>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    gap: 15,
    justifyContent: 'flex-start'
  }
}))(MuiDialogActions);

export default function CustomDialog(props) {
  const { theme } = React.useContext(ThemeContext);
  const t = useAutoTranslation();

  const useStyles = makeStyles({
    dialog: {
      position: 'absolute',
      // left: 400,
      top: (props) => props.distanceTop || 10,
      minHeight: (props) => props.minHeight || '78vh',
      backgroundColor: theme.appearance.color,
      color: theme.appearance.text
    }
  });

  const {
    open,
    title,
    handleClose,
    minHeight,
    titleAppearance,
    allowBackgroundClose,
    showCancelBtn,
    saveBtnLabel,
    distanceTop,
    cancelBtnLabel
  } = props;
  const classes = useStyles({ minHeight, distanceTop });

  const [loading, setLoading] = React.useState(false);
  const saveClickHandler = () => {
    setLoading(true);
    props
      .onSave()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CancelActionBtn = ({ label, handleClose }) => {
    return (
      <Button variant="outlined" onClick={handleClose}>
        {t(label) || t('Cancel')}
      </Button>
    );
  };

  const onClose = (e, reason) => {
    if (
      allowBackgroundClose ||
      (reason !== 'escapeKeyDown' && reason !== 'backdropClick')
    ) {
      return handleClose(e);
    }
  };

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      onKeyDown={stopPropagationForTab}
      aria-labelledby="p-dialog"
      open={open}
      fullWidth
      maxWidth={props.maxWidth || 'sm'}
      classes={{
        paper: classes.dialog
      }}
    >
      <DialogTitle
        id="p-dialog"
        onClose={handleClose}
        appearance={titleAppearance}
      >
        {t(title)}
      </DialogTitle>
      <DialogContent dividers>
        {props.children ? (
          React.cloneElement(props.children, { ...props })
        ) : (
          <Typography gutterBottom>{t(props.content)}</Typography>
        )}
      </DialogContent>
      {props.showActions && (
        <DialogActions>
          <Button
            onClick={saveClickHandler}
            color="secondary"
            variant="contained"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={15} /> : undefined}
          >
            {t(saveBtnLabel) || t('Save')}
          </Button>
          <CancelActionBtn label={cancelBtnLabel} handleClose={handleClose} />
          {props.onSavePDFCopy && (
            <Button
              onClick={props.onSavePDFCopy}
              color="secondary"
              variant="contained"
            >
              {t('Save PDF Copy')}
            </Button>
          )}
        </DialogActions>
      )}
      {showCancelBtn && (
        <DialogActions>
          <CancelActionBtn handleClose={handleClose} label={saveBtnLabel} />
        </DialogActions>
      )}
    </Dialog>
  );
}
