import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import TextInput from 'src/components/UI/input/TextInput';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import axios from 'src/axios';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DateInput from 'src/components/UI/input/DateInput';
import { Box, Button, MenuItem, Select, TextField } from '@mui/material';

import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackContext, ThemeContext, AppContext } from 'src/store/ContextStore';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getReferralTags, handleUpload } from './formService';

export default function AppointmentForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        clinicName: '',
        appointmentId: '',
        appointmentDate: new Date(),
        appointmentTime: dayjs(new Date()), //Sort this
        clinicianName: '', //Open Closed
        appointmentStatus: 'Closed',
        appointmentType: '',
        location: 'Telephone', //(Telephone, Video, Face to Face, , +Allow users to add their own locations)
        associatedReferral: '', //(Dropdown to Select from Patient Referrals)
        fileAttachments: [],
        appointmentNotes: '',
        showInFamily: true
      };
  const { theme } = useContext(ThemeContext);

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([
    'Self',
    'GP',
    'Hospital',
    'Private Provider'
  ]);

  const [locationOptions] = React.useState([
    'Telephone',
    'Video',
    'Face to Face',
    'Private Provider'
  ]);

  let [referralOptions, setReferralOptions] = React.useState(['loading...']);

  React.useEffect(() => {
    const fetchData = async () => {
      const referralTags = await getReferralTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setReferralOptions(referralTags);
    };
    if (referralOptions != [] && referralOptions[0] == 'loading...')
      fetchData();
  });

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
  };

  const { setSnack } = React.useContext(SnackContext);

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };
  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.appointment, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Clinic Name</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="clinicName"
              value={formValues.clinicName}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="appointmentID"
              value={formValues.appointmentID}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="appointmentStatus"
                value={formValues.appointmentStatus}
                onChange={handleChange}
              >
                {['Open', 'Closed'].map((option, i) => (
                  <MenuItem value={option} key={i} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointent Date</FormLabel>
          </Grid>
          <DateInput
            value={
              formValues.appointmentDate
                ? new Date(formatDate(formValues.appointmentDate))
                : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                appointmentDate: e
              }))
            }
            variant="outline"
            minDate={new Date()}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Time</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={formValues.appointmentTime}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    appointmentTime: e
                  }));
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Appointment Type"
              type="autocomplete"
              name="appointmentType"
              value={formValues.appointmentType || null}
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  appointmentType: value.label
                });
              }}
              options={typeOptions || []}
              helperText="Please select or enter an appointment type"
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Location"
              type="autocomplete"
              name="location"
              value={formValues.location || null}
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  location: value
                });
              }}
              options={locationOptions || []}
              helperText="Please select or enter a location"
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Referral</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="associatedReferral"
                value={formValues.associatedReferral}
                onChange={handleChange}
              >
                {referralOptions.map((option, i) => (
                  <MenuItem value={option} key={i} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Appointment Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="appointmentNotes"
              value={formValues.appointmentNotes}
              multiline={true}
            />
          </Box>
        </Grid>
        <Grid item xs={7} style={{ paddingTop: '1.5rem' }}>
          <FormLabel>File Attachments </FormLabel>
        </Grid>
        <Grid item xs={5}>
          <Button variant="contained" color="secondary" component="label">
            Upload Attachment
            <input
              accept="image/*,.pdf,.txt"
              hidden
              type="file"
              onChange={handleFile}
            />
          </Button>
          <Stack spacing={1} style={{ paddingTop: '1rem' }}>
            {formValues.fileAttachments.map((attachment, i) => (
              <Chip
                key={i}
                label={attachment}
                variant="outlined"
                onDelete={() => handleDelete(i)}
              />
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          addLabel={`Add new appointment`}
        />
      </Grid>
    </form>
  );
}
