import React from 'react';
import { useQuill } from 'react-quilljs';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';

function Editor({ value, onChange }) {
  const theme = 'snow'; //Should this be part of themecontext

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'video']
    ]
  };

  const formats = ['bold', 'italic', 'underline', 'strike'];

  return (
    <div>
      <ReactQuill theme={theme} modules={modules} formats={formats} />
    </div>
  );
}

export default Editor;
