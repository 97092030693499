import { transform, isEqual, isObject, findIndex } from 'lodash';
import {
  RECAPTCHA_SITE_KEY,
  APICONSTANTS,
  PHENOTYPES_CONSTANTS,
  HPO_CONSTANTS,
  CONSTANTS
} from 'src/components/Constants';
import { GENDER } from 'src/components/questionnaire/Constants';

// function to capitalize the input
export const capitalizeLetter = (word) => {
  if (typeof word !== 'string') return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const scrollToTop = (top = 0) => {
  window.scrollTo({
    top: top,
    left: 0,
    behavior: 'smooth'
  });
};

// If any of field have error then return true-
export const inverseValidationLogicForPersonDetails = (
  formValues,
  labels,
  isEnablePregnancy
) => {
  for (let key in labels) {
    // check for required validation if field is required & field is enabled
    let required = labels[key].required && labels[key].enabled;
    if (required) {
      // Check pregnancy is required or not-
      if (key === 'pregnancy') {
        if (
          isEnablePregnancy &&
          (formValues.gender === GENDER.female ||
            formValues.gender === GENDER.unknown)
        ) {
          let value = formValues['isPregnancies'];
          if (!value) {
            return true;
          }
        }
      } else {
        let value = formValues[key];
        if (!value) {
          return true;
        }
      }
    }
  }

  return false;
};

export const calculateAge = (dob, dod = null) => {
  if (dob) {
    let birthday = dob;
    if (typeof dob === 'string') birthday = new Date(dob);

    let today = Date.now();
    // if date of death is provided, use that to calculate diff
    if (dod) today = new Date(dod);

    const month_diff = today - birthday.getTime();
    const age_dt = new Date(month_diff);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  } else return '';
};

export const downloadURI = (uri, fileName) => {
  const a = document.getElementById('img-download');
  a.href = uri;
  if (fileName) a.download = fileName;
  a.click();
};

export const downloadXML = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/xml' });
  const uri = URL.createObjectURL(blob);
  downloadURI(uri, `${fileName}.xml`);
};

export const downloadFile = (data, fileName, fileType) => {
  const blob = new Blob([data], { type: `text/${fileType}` });
  const uri = URL.createObjectURL(blob);
  downloadURI(uri, `${fileName}.${fileType}`);
};

export const validatePhn = (value) => {
  const re = /^[0-9*)(+. -]+$/g;
  return re.test(value);
};

export const validateNumber = (value) => {
  const re = /^[0-9]*$/g;
  return re.test(value);
};

export const validateAge = (value) => {
  return validateNumber(value) && parseInt(value) < 200;
};

export const validateDate = (value) => {
  return (
    Object.prototype.toString.call(value) === '[object Date]' &&
    !isNaN(value.getTime())
  );
};

export const parseDate = (date, { parseYear } = {}) => {
  if (date) {
    date = new Date(date);
    if (parseYear) return date.getFullYear();
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  } else return '';
};

export const formatDate = (value) => {
  if (value) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  } else return '';
};

export const parseYear = (date) => {
  if (date) {
    date = new Date(date);
    return `${date.getFullYear()}`;
  } else return '';
};

export const validateEmail = (email) => {
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  return false;
};

export const copyToClipBoard = (value) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(value).catch(() => {});
  }
  // fallback copy to clipboard
  else if (window.clipboardData && window.clipboardData.setData) {
    window.clipboardData.setData('Text', value);
  } else {
    const textarea = document.createElement('textarea');
    textarea.textContent = value;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      // nothing to do
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

// function to remove blank values and keys of removals array based on boolean
export const cleanFormData = (
  formValues,
  removals = [],
  onlyRemovals = false
) => {
  const data = {};
  for (const key in formValues) {
    // if the key is not included in removals and value exists for it
    if (!removals.includes(key) && (onlyRemovals || !!formValues[key])) {
      data[key] = formValues[key];
    }
  }
  return data;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function compareObjects(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? compareObjects(value, base[key])
          : value;
    }
  });
}

/**
 * Diff between two arrays, this will return whole array, even if one object is changed
 * @param {Array} updated New updated value
 * @param {Array} previous Previous value (in recent changes)
 */
export function compareArrays(updated, previous) {
  return updated.filter((x) => {
    return findIndex(previous, x) === -1;
  });
}

/**
 * Merge two object
 * @param  {Object} obj1  Object defaultValues
 * @param  {Object} obj2  Object values from DB
 * @return {Object}       Return a new object who represent the merge of obj1 & obj2
 */
export const mergeObjects = (obj1 = {}, obj2 = {}) => {
  const mergedObj = {};

  // Iterate through the keys in obj1
  for (let key in obj1) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj2.hasOwnProperty(key)) {
      // If the key exists in both objects, merge the properties
      mergedObj[key] = {
        ...obj1[key],
        ...obj2[key]
      };
    } else {
      // If the key only exists in obj1, add it to the merged object
      mergedObj[key] = obj1[key];
    }
  }

  return mergedObj;
};

export const passwordRandomChars =
  '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';

// regex to check for atleast 8 chars, small, caps, 1+ numeber & 1+ special char
export function validatePasswordStrength(value) {
  const re = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9 ]).*/;
  return re.test(value);
}

export function injectGoogleCaptchaScript(callback, siteKey) {
  const script = document.createElement('script');
  script.id = 'g-recaptcha';
  script.src = `https://www.google.com/recaptcha/api.js?trustedtypes=true&render=${siteKey}`;
  if (callback) {
    script.onload = callback;
  }
  document.body.appendChild(script);
}

export const showPregnanciesSection = (isEnablePregnancy, personalDetails) => {
  return (
    isEnablePregnancy &&
    personalDetails?.gender !== 'male' &&
    personalDetails?.isPregnancies === 'true'
  );
};

export const getLabelFromValueOptions = (value, options) => {
  if (!value || value === '') {
    return '';
  }

  if (!options) return '';
  let option = options.find((o) => o.value == value);
  if (!option) return '';

  return option.label;
};

export const getColor = () => {
  const range = {
    min: 150,
    max: 255
  };

  const randomColor = () =>
    Math.floor(Math.random() * (range.max - range.min + 1) + range.min);

  const uniqueColor = () =>
    `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`;

  return uniqueColor();
};

export function generateRandomPassword(length = 10) {
  let password = '';
  for (let i = 0; i <= length; i++) {
    const randomNumber = Math.floor(Math.random() * passwordRandomChars.length);
    password += passwordRandomChars[randomNumber];
  }
  return password;
}

export const getPhenotypeSearchURL = (searchTerm, currentPage) => {
  const URL = `${PHENOTYPES_CONSTANTS['hpoBaseURL']}${PHENOTYPES_CONSTANTS['searchHPOTerm']}?q=${searchTerm}&page=${currentPage}&limit=${PHENOTYPES_CONSTANTS['maxLimit']}`;
  return URL;
};

export const getHPOSearchURL = (searchTerm, currentPage, category) => {
  const URL = `${HPO_CONSTANTS['hpoBaseURL']}${HPO_CONSTANTS[
    'searchHPOTerm'
  ].replace(':category', category)}?q=${searchTerm}&page=${currentPage}&limit=${
    HPO_CONSTANTS['maxLimit']
  }`;
  return URL;
};

// check belongs genetics group
export const isGeneticsServiceUser = (userType) =>
  userType === CONSTANTS.userTypes.GENETICS ||
  userType === CONSTANTS.userTypes.GENETICS_SERVICE_ADMIN ||
  userType === CONSTANTS.userTypes.GENETICS_USER ||
  // TODO remove this admin check
  userType === CONSTANTS.userTypes.ADMIN;

export const isGeneticsServiceAdmin = (userType) =>
  userType === CONSTANTS.userTypes.GENETICS ||
  userType === CONSTANTS.userTypes.GENETICS_SERVICE_ADMIN ||
  // TODO remove this admin check
  userType === CONSTANTS.userTypes.ADMIN;
