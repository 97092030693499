import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'src/axios';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import SelectBar from 'src/components/pedigree/SelectBar';
import { isGeneticsServiceUser } from 'src/util/util';
import { AppContext, UserContext } from 'src/store/ContextStore';
import EpicEMRWrapper from 'src/components/integrations/epic/EpicEMRWrapper';
import { useIntegrationsContext } from 'src/components/integrations/IntegrationsContext';
import { EVENTS as INTEGRATION_EVENTS } from 'src/components/integrations/integrationsReducer';
import { APICONSTANTS } from '../Constants';
import { EVENTS } from '../admin/appReducer';

function PedigreePanel({ patient }) {
  return (
    <Grid container>
      <HeaderTitle text={'Pedigree Tool'} />
      <Grid item xs={12}>
        <SelectBar patient={patient} />
      </Grid>
    </Grid>
  );
}

// TODO find a better way to add feature check for custom hook

const PedigreePanelWrapper = ({ patient }) => {
  const {
    user: { userType }
  } = React.useContext(UserContext);

  // fetch epic emr integration status for genetics
  const requiresFetchingEpicEmrStatus = isGeneticsServiceUser(userType);
  const [loading, setLoading] = React.useState(requiresFetchingEpicEmrStatus);

  const {
    content: {
      settings: { epicEmrApi }
    },
    dispatchUpdate
  } = React.useContext(AppContext);

  const { dispatchUpdate: dispatchIntegrationUpdate } =
    useIntegrationsContext();

  React.useEffect(() => {
    if (requiresFetchingEpicEmrStatus) {
      // fetch emr settings on mount only for specific users
      axios
        .get(APICONSTANTS.emrSettingsStatus)
        .then((res) => {
          if (res.data) {
            // if access token is already present set it to the state
            if (res.data.epicEmrState) {
              dispatchIntegrationUpdate({
                type: INTEGRATION_EVENTS.SET_EPIC_EMR_STATE,
                payload: res.data.epicEmrState
              });
            }

            dispatchUpdate({
              type: EVENTS.SET_SETTINGS,
              tab: 'epicEmrApi',
              value: res.data.epicEmrApi
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress size={30} />;
  }

  if (epicEmrApi?.enabled) {
    return (
      <EpicEMRWrapper emrSettings={epicEmrApi}>
        <PedigreePanel patient={patient} />
      </EpicEMRWrapper>
    );
  }

  return <PedigreePanel patient={patient} />;
};

export default PedigreePanelWrapper;
