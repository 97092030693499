import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import BallotIcon from '@mui/icons-material/Ballot';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DescriptionIcon from '@mui/icons-material/Description';
import AssistantIcon from '@mui/icons-material/Assistant';
import LabelIcon from '@mui/icons-material/Label';
import GetAppIcon from '@mui/icons-material/GetApp';
import { LocalOffer } from '@mui/icons-material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { GENDER } from 'src/components/questionnaire/Constants';

export const SIDEBAR_VALUE = {
  legend: 'legend',
  person: 'person',
  familyHistory: 'family-history',
  addRelative: 'add-relative',
  labels: 'labels',
  // epic integration
  emr: 'emr',
  tags: 'tags',
  riskFactors: 'risk-factors',
  phenotypes: 'phenotypes',
  genotypes: 'genotypes',
  diagnoses: 'diagnoses',
  clinicalNotes: 'clinical-notes',
  suggestions: 'suggestions',
  export: 'export',
  setupPatientAccess: 'setup-patient-access'
};

export const SIDEBAR_ITEMS = [
  {
    label: 'LEGEND',
    value: SIDEBAR_VALUE.legend,
    icon: <DashboardIcon />
  },
  {
    label: 'LABELS',
    value: SIDEBAR_VALUE.labels,
    icon: <LabelIcon />
  },
  {
    label: 'TAGS',
    value: SIDEBAR_VALUE.tags,
    icon: <LocalOffer />
  },
  {
    label: 'FAMILY HISTORY',
    value: SIDEBAR_VALUE.familyHistory,
    icon: <AccountTreeIcon />
  },
  {
    label: 'CLINICAL RECORDS',
    value: SIDEBAR_VALUE.clinicalNotes,
    icon: <DescriptionIcon />
  },
  {
    label: 'SUGGESTIONS',
    value: SIDEBAR_VALUE.suggestions,
    icon: <AssistantIcon />
  },
  {
    label: 'EXPORT',
    value: SIDEBAR_VALUE.export,
    icon: <GetAppIcon />
  }
];

export const getUserSidebarItems = ({ showPatientAccessLabel, showEmrTab }) =>
  [
    {
      label: 'LEGEND',
      value: SIDEBAR_VALUE.legend,
      icon: <DashboardIcon />
    },
    {
      label: 'ADD RELATIVE',
      value: SIDEBAR_VALUE.addRelative,
      icon: <PersonAddIcon />
    },
    {
      label: 'LABELS',
      value: SIDEBAR_VALUE.labels,
      icon: <LabelIcon />
    },
    // only show EMR if enabled
    ...(showEmrTab
      ? [
          {
            label: 'EMR',
            value: SIDEBAR_VALUE.emr,
            icon: <LabelIcon />
          }
        ]
      : []),
    {
      label: 'PERSON',
      value: SIDEBAR_VALUE.person,
      icon: <PersonIcon />
    },
    {
      label: 'FAMILY HISTORY',
      value: SIDEBAR_VALUE.familyHistory,
      icon: <AccountTreeIcon />
    },
    // Show when user is proband & don't have email
    showPatientAccessLabel && {
      label: 'SET UP PATIENT ACCESS',
      value: SIDEBAR_VALUE.setupPatientAccess,
      icon: <VerifiedUserIcon />
    },
    {
      label: 'RISK FACTORS',
      value: SIDEBAR_VALUE.riskFactors,
      icon: <AssignmentLateIcon />
    },
    {
      label: 'PHENOTYPES',
      value: SIDEBAR_VALUE.phenotypes,
      icon: <BallotIcon />
    },
    {
      label: 'GENOTYPES',
      value: SIDEBAR_VALUE.genotypes,
      icon: <BlurLinearIcon />
    },
    {
      label: 'DIAGNOSES',
      value: SIDEBAR_VALUE.diagnoses,
      icon: <LocalHospitalIcon />
    },
    {
      label: 'CLINICAL RECORDS',
      value: SIDEBAR_VALUE.clinicalNotes,
      icon: <DescriptionIcon />
    },
    {
      label: 'AI ASSISTANT',
      value: SIDEBAR_VALUE.suggestions,
      icon: <AssistantIcon />
    },
    {
      label: 'EXPORT',
      value: SIDEBAR_VALUE.export,
      icon: <GetAppIcon />
    }
  ].filter(Boolean);

export const STUDENT_SIDEBAR_ITEMS = [
  {
    label: 'LEGEND',
    value: SIDEBAR_VALUE.legend,
    icon: <DashboardIcon />
  },
  {
    label: 'LABELS',
    value: SIDEBAR_VALUE.labels,
    icon: <LabelIcon />
  },
  {
    label: 'EXPORT',
    value: SIDEBAR_VALUE.export,
    icon: <GetAppIcon />
  }
];

export const getStudentUserSidebarItems = (showPatientAccessLabel) =>
  [
    {
      label: 'LEGEND',
      value: SIDEBAR_VALUE.legend,
      icon: <DashboardIcon />
    },
    {
      label: 'ADD RELATIVE',
      value: SIDEBAR_VALUE.addRelative,
      icon: <PersonAddIcon />
    },
    {
      label: 'LABELS',
      value: SIDEBAR_VALUE.labels,
      icon: <LabelIcon />
    },
    {
      label: 'PERSON',
      value: SIDEBAR_VALUE.person,
      icon: <PersonIcon />
    },
    // Show when user is proband & don't have email
    showPatientAccessLabel && {
      label: 'SET UP PATIENT ACCESS',
      value: SIDEBAR_VALUE.setupPatientAccess,
      icon: <VerifiedUserIcon />
    },
    {
      label: 'EXPORT',
      value: SIDEBAR_VALUE.export,
      icon: <GetAppIcon />
    }
  ].filter(Boolean);

export const checkRelationValid = (selected_gender, deepCopy, formValues) => {
  const spouse = deepCopy.find((p) => p.pid === formValues.spouse);

  const child = deepCopy.some(
    (p) =>
      (p.mother === formValues.pid || p.father === formValues.pid) && !p.hidden
  );

  if (!spouse || !child) return { status: true };

  if (
    (selected_gender === GENDER.female &&
      [GENDER.female, GENDER.unknown].includes(spouse.gender)) ||
    (spouse.gender === GENDER.female &&
      [GENDER.female, GENDER.unknown].includes(selected_gender))
  ) {
    return {
      status: false,
      message:
        'This relationship has offspring and no valid sperm donor detected.  Please update gender. '
    };
  }

  const relationValid =
    (selected_gender === GENDER.female && spouse.gender === GENDER.male) ||
    (spouse.gender === GENDER.female && selected_gender === GENDER.male);

  if (relationValid) {
    return { status: true };
  } else {
    return {
      status: false,
      message:
        ' This relationship has offspring and no valid egg donor detected.  Please update gender. '
    };
  }
};
