import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import axios from 'src/axios';

import { Box, MenuItem, Select, TextField } from '@mui/material';
import DateInput from '../../../UI/input/DateInput';

import { DatePicker } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackContext, AppContext } from 'src/store/ContextStore';
import { getConsentTags } from './formService';
import { ThemeContext } from 'src/store/ContextStore';

export default function SampleForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { theme } = useContext(ThemeContext);
  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        sampleType: '',
        dateTaken: new Date(),
        trackingId: '',
        sampleStatus: '',
        associatedConsent: ''
      };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);
  let [consentOptions, setConsentOptions] = React.useState(['loading...']);
  React.useEffect(() => {
    const fetchData = async () => {
      const consentTags = await getConsentTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setConsentOptions(consentTags);
    };
    if (consentOptions != [] && consentOptions[0] == 'loading...') fetchData();
  }, []);

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.sample, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Tracking ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="trackingId"
              value={formValues.trackingId}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Sample Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="sampleStatus"
              value={formValues.sampleStatus}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Sample Type</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="sampleType"
              value={formValues.sampleType}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Date Taken</FormLabel>
          </Grid>
          <DateInput
            name="dateTaken"
            value={
              formValues.dateTaken
                ? new Date(formatDate(formValues.dateTaken))
                : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                appointmentDate: e
              }))
            }
            variant="outline"
            minDate={new Date()}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Consent</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="associatedConsent"
                value={formValues.associatedConsent}
                onChange={handleChange}
              >
                {consentOptions.map((option) => (
                  <MenuItem value={option} key={option} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          addLabel={`Add new sample details`}
        />
      </Grid>
    </form>
  );
}
