import React from 'react';
//Styles
import styles from './Preview.module.css';
import { FORM_TYPES } from 'src/components/form-builder/CreateQuestionnaire/constants/formTypes';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { Alert } from '@mui/material';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import SignatureCanvas from 'react-signature-canvas';

function PreviewForm({ data }) {
  let specificCondition = data?.condition;
  const t = useAutoTranslation();
  return (
    <div>
      {specificCondition?.sectionId && (
        <div style={{ marginBlock: '20px' }}>
          <Alert severity="info">{` ${
            data.formType ? 'This Form' : `The ${data.title} section`
          } will only render when ${specificCondition?.condition} value is ${
            specificCondition?.value
          }`}</Alert>
        </div>
      )}
      <div
        style={{
          paddingInline: '10px',
          paddingBlock: '20px'
        }}
      >
        <HeaderTitle text={data.title} />
        <div style={{ paddingBlock: 10 }}>{t(data.description)}</div>
      </div>
      <Box>
        {data.formElements.map((element) => {
          switch (element.type) {
            case FORM_TYPES.SHORT_TEXT:
              return (
                <div style={{ margin: '10px' }}>
                  <FormLabel>
                    <div className={styles.previewLabel}>
                      {t(element.label)}
                      {element.isRequired && (
                        <span style={{ color: 'red', padding: 5 }}>*</span>
                      )}
                    </div>
                  </FormLabel>
                  <TextField variant="outlined" fullWidth />
                </div>
              );
            case FORM_TYPES.FILE_UPLOAD:
              return (
                <div style={{ margin: '10px' }}>
                  <FormLabel>
                    <div className={styles.previewLabel}>
                      {t(element.label)}
                      {element.isRequired && (
                        <span style={{ color: 'red', padding: 5 }}>*</span>
                      )}
                    </div>
                  </FormLabel>
                  <Button
                    variant="contained"
                    color="secondary"
                    component="label"
                  >
                    Upload File
                    <input accept="image/*" hidden type="file" />
                  </Button>
                </div>
              );
            case FORM_TYPES.EMAIL_INPUT:
              return (
                <div style={{ margin: '10px' }}>
                  <FormLabel>
                    <div className={styles.previewLabel}>
                      {t(element.label)}
                      {element.isRequired && (
                        <span style={{ color: 'red', padding: 5 }}>*</span>
                      )}
                    </div>
                  </FormLabel>
                  <TextField variant="outlined" type={'email'} fullWidth />
                </div>
              );
            case FORM_TYPES.DATE_TIME_INPUT:
              return (
                <div style={{ margin: '10px' }}>
                  <FormLabel>
                    <div className={styles.previewLabel}>
                      {t(element.label)}
                      {element.isRequired && (
                        <span style={{ color: 'red', padding: 5 }}>*</span>
                      )}
                    </div>
                  </FormLabel>
                  <TextField variant="outlined" type={'date'} fullWidth />
                </div>
              );
            case FORM_TYPES.LONG_TEXT:
              return (
                <div style={{ marginBlock: '10px', padding: '10px' }}>
                  <FormLabel>
                    <div className={styles.previewLabel}>
                      {t(element.label)}
                      {element.isRequired && (
                        <span style={{ color: 'red', padding: 5 }}>*</span>
                      )}
                    </div>
                  </FormLabel>
                  <TextField
                    label={element.label}
                    required={element.isRequired}
                    multiline
                    rows={5}
                    variant="outlined"
                    style={{ width: '85%' }}
                  />
                </div>
              );
            case FORM_TYPES.MULTIPLE_CHOICE:
              return (
                <div
                  style={{
                    padding: '10px',
                    marginBlock: '10px'
                  }}
                >
                  <FormControl>
                    <FormLabel>
                      <div className={styles.previewLabel}>
                        {t(element.label)}
                        {element.isRequired && (
                          <span style={{ color: 'red', padding: 5 }}>*</span>
                        )}
                      </div>
                    </FormLabel>
                    <RadioGroup>
                      {element.options.map((option, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            style={{
                              marginBlock: 10
                            }}
                            value={option}
                            control={<Radio />}
                            label={t(option)}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              );
            case FORM_TYPES.DROPDOWN:
              return (
                <div style={{ padding: '10px', marginBlock: '10px' }}>
                  <FormControl>
                    <FormLabel>
                      <div className={styles.previewLabel}>
                        {t(element.label)}
                        {element.isRequired && (
                          <span style={{ color: 'red', padding: 5 }}>*</span>
                        )}
                      </div>
                    </FormLabel>
                    <Select
                      style={{ paddingRight: 15 }}
                      variant="outlined"
                      defaultValue={element.options[0]}
                      autoWidth
                      // label={element.label}
                    >
                      {element.options.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(option)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              );
            case FORM_TYPES.CHECKBOX_INPUT:
              if (element.isIdentifier && !element.options.includes('Other')) {
                element.options.push('Other');
              }
              return (
                <div
                  style={{
                    padding: '10px',
                    marginBlock: '15px'
                  }}
                >
                  <Box>
                    <FormLabel>
                      <div className={styles.previewLabel}>
                        {t(element.label)}
                        {element.isRequired && (
                          <span style={{ color: 'red', padding: 5 }}>*</span>
                        )}
                      </div>
                    </FormLabel>
                    <FormGroup>
                      {element.options.map((option, index) => {
                        return (
                          <>
                            <FormControlLabel
                              key={index}
                              control={<Checkbox />}
                              label={t(option)}
                            />
                            {option === 'Other' && (
                              <div style={{ paddingBlock: '10px' }}>
                                <TextField
                                  disabled
                                  placeholder="Only enabled when 'Other' option is selected"
                                  variant="outlined"
                                  style={{ width: '85%' }}
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </FormGroup>
                  </Box>
                </div>
              );
            case FORM_TYPES.LINEAR_SCALE:
              return (
                <div
                  style={{
                    paddingInline: '10px',
                    marginBlock: '15px'
                  }}
                >
                  <div>
                    <FormLabel>
                      <div className={styles.previewLabel}>
                        {t(element.label)}
                        {element.isRequired && (
                          <span style={{ color: 'red', padding: 5 }}>*</span>
                        )}
                      </div>
                    </FormLabel>
                    <RadioGroup row>
                      {element.options.map((option, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '15px'
                            }}
                          >
                            <span>{t(option)}</span>
                            <FormControlLabel
                              style={{ marginLeft: '10px' }}
                              value={option}
                              control={<Radio />}
                            />
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </div>
                </div>
              );

            case FORM_TYPES.EMBED_CONTENT:
              return (
                <div
                  style={{
                    paddingInline: '10px',
                    marginBlock: '15px'
                  }}
                >
                  <div>
                    <div
                      dangerouslySetInnerHTML={{ __html: t(element.label) }}
                    />
                  </div>
                </div>
              );
            case FORM_TYPES.SIGNATURE:
              return (
                <div
                  style={{
                    paddingInline: '10px',
                    marginBlock: '15px'
                  }}
                >
                  <FormLabel>
                    <div className={styles.previewLabel}>
                      {t(element.label)}
                      {element.isRequired && (
                        <span style={{ color: 'red', padding: 5 }}>*</span>
                      )}
                    </div>
                  </FormLabel>
                  <SignatureCanvas />
                </div>
              );
            default:
              return <></>;
          }
        })}
      </Box>
    </div>
  );
}

export default PreviewForm;
