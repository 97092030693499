import React from 'react';
import { useHistory } from 'react-router';
import Typography from '@mui/material/Typography';
import Table from 'src/components/UI/table/Table';
import {
  AppContext,
  LoadingContext,
  SnackContext
} from 'src/store/ContextStore';
import { capitalizeLetter, downloadFile } from 'src/util/util';
import CustomIconBtn from 'src/components/UI/button/CustomIconBtn';
import { parseDate } from 'src/util/util';
import {
  APICONSTANTS,
  CONSTANTS,
  GLOBAL_CONSTANTS
} from 'src/components/Constants';
import axios from 'src/axios';
import { EVENTS } from 'src/components/admin/appReducer';
import {
  diagnosesMap,
  relationshipMap
} from 'src/components/questionnaire/Constants';
import UserTableActions from './UserTableActions';
import UsersTableStatusCol from './UsersTableStatusCol';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { first } from 'lodash';
import { Visibility } from '@mui/icons-material';

export default function UsersTableInfo({
  openDeleteModal,
  patient,
  markAsImported
}) {
  const history = useHistory();
  const tableRef = React.useRef(null);

  const { setLoading } = React.useContext(LoadingContext);
  const { setSnack } = React.useContext(SnackContext);
  const {
    content: { probandUsers },
    dispatchUpdate
  } = React.useContext(AppContext);

  React.useEffect(() => {
    if (probandUsers?.length === 0) {
      setLoading(true);
    }
    // check if detail panel is opened - then fetch family for that user too
    const selectedUser = probandUsers?.find(
      (el) => !!el.tableData.showDetailPanel
    );
    axios
      .get(
        `${APICONSTANTS.getProbandUsers}${
          selectedUser ? `?selectedUser=${selectedUser._id}` : ''
        }`
      )
      .then((res) => {
        setLoading(false);
        dispatchUpdate({
          type: EVENTS.MERGE_AND_SET_TAB,
          tab: 'probandUsers',
          value: res.data
        });
      })
      .catch(() => {
        setLoading(false);
        setSnack({
          status: true,
          msg: 'Error fetching values',
          severity: 'error'
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousSelectedId = React.useMemo(() => {
    return probandUsers?.length
      ? probandUsers.findIndex((el) => !!el.tableData?.showDetailPanel)
      : -1;
  }, [probandUsers]);

  const fetchFamilyMembers = async (_id, selectedId, userStatus) => {
    return axios
      .get(APICONSTANTS.getFamiliesInfo.replace(':user_id', _id))
      .then((res) => {
        // update the state with family members
        res.data.map(
          (r) =>
            (r.disableEdit =
              userStatus === CONSTANTS.user_status.IMPORTED ||
              userStatus !== CONSTANTS.user_status.SUBMITTED)
        );
        const _probandUsers = [...probandUsers];
        _probandUsers[selectedId] = {
          ...probandUsers[selectedId],
          boxRows: res.data
        };

        dispatchUpdate({
          type: EVENTS.SET_TAB_ALL,
          tab: 'probandUsers',
          value: _probandUsers
        });
        return;
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error fetching values!',
          severity: 'error'
        });
        return;
      });
  };

  // function to get family data specific to selected user
  const getFamilyData = (rowData) => {
    if (!tableRef.current) return;

    const selectedId = rowData.tableData.id;

    // toggle previously clicked panel if already opened
    // showDetailPanel checks if panel is opened - handles scenario where previousSelectedId didn't update
    if (
      previousSelectedId > -1 &&
      previousSelectedId !== selectedId &&
      probandUsers[previousSelectedId].tableData.showDetailPanel
    ) {
      tableRef.current.onToggleDetailPanel(
        [previousSelectedId],
        tableRef.current.props.detailPanel
      );
    }
    // setPreviousSelectedId(selectedId)

    // no need to make api call if link status is created
    if (rowData.status === CONSTANTS.user_status.CREATED) return [];

    // if no family data present - fetch it
    if (rowData.status !== CONSTANTS.user_status.PENDING) {
      if (!rowData.boxRows || rowData.boxRows.length === 0) setLoading(true);
      fetchFamilyMembers(rowData._id, selectedId, rowData.status).then(() =>
        setLoading(false)
      );
    }
  };

  const onViewClick = (e, { user_id, _id }, isFamilyMember = false) => {
    e.stopPropagation();
    if (isFamilyMember) {
      history.push(
        CONSTANTS.familyMemberInfoPath
          .replace(':user_id', user_id)
          .replace(':member_id', _id)
      );
    } else history.push(`${CONSTANTS.probandEditPath}/${_id}`);
  };

  const onViewPatientClick = (e, { _id }) => {
    e.stopPropagation();
    history.push(`${CONSTANTS.probandViewPath}/${_id}`);
  };

  // function when download xml btn is clicked
  const getXMLData = (e, { _id, familyId }) => {
    e.stopPropagation();
    axios
      .get(APICONSTANTS.getXMLData.replace(':user_id', _id))
      .then((res) => {
        downloadFile(res.data, familyId, 'xml');
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error exporting as xml data',
          severity: 'error'
        });
      });
  };

  const getJsonData = (e, { _id, familyId }) => {
    e.stopPropagation();
    axios
      .get(APICONSTANTS.getGA4JsonData.replace(':user_id', _id))
      .then((res) => {
        downloadFile(JSON.stringify(res.data), familyId, 'json');
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error converting to JSON',
          severity: 'error'
        });
      });
  };

  const createUniqueLink = (token) => {
    return `${window.location.origin}/questionnaire?${GLOBAL_CONSTANTS.tokenKey}=${token}`;
  };

  const patientView = (token) => {
    window.open(createUniqueLink(token), '_blank');
  };

  const t = useAutoTranslation();
  const customSearch = (term, rowData) => {
    if (!rowData.firstName) rowData.firstName = '';
    const currentUser = probandUsers.filter((user) => {
      return user.currentId === rowData.currentId;
    })[0];
    let found = false;
    if (currentUser.pedigreeConfig?.length > 0) {
      for (let x in currentUser.pedigreeConfig) {
        let {
          firstName = '',
          lastName = '',
          comments = '',
          gender = '',
          relationship = ''
        } = currentUser.pedigreeConfig[x];
        term = term.toLowerCase();
        if (firstName.toLowerCase().includes(term)) found = true;
        if (lastName.toLowerCase().includes(term)) found = true;
        if (comments.toLowerCase().includes(term)) found = true;
        if (gender.toLowerCase().includes(term)) found = true;
        if (relationship.toLowerCase().includes(term)) found = true;
      }
    }
    return rowData.firstName.toLowerCase().includes(term) || found;
  };

  const columns = [
    // { title: "#", render: rowData => rowData.tableData.id + 1, width: 10, sorting: false },
    { title: t('Family ID'), field: 'familyId' },
    { title: t('TrakGene ID'), field: 'trakGeneId' },
    {
      title: t('First Name'),
      field: 'firstName',
      render: (rowData) => capitalizeLetter(rowData.firstName),
      customFilterAndSearch: customSearch
    },
    {
      title: t('Last Name'),
      field: 'lastName',
      render: (rowData) => capitalizeLetter(rowData.lastName)
    },
    {
      title: t('Date of Birth'),
      field: 'dob',
      render: (rowData) => parseDate(rowData.dob)
    },
    { title: t('E-mail'), field: 'email', sorting: false },
    {
      title: t('Status'),
      render: (rowData) => (
        <UsersTableStatusCol
          status={rowData.status}
          submitted={rowData.submitted}
        />
      )
    },
    {
      title: t('Actions'),
      sorting: false,
      cellStyle: !patient && { padding: '0 0 0 0px' },
      width: 190,
      render: (rowData) =>
        patient ? (
          <CustomIconBtn
            type="view"
            tooltip="View / Edit"
            onClick={(e) => patientView(rowData.token)}
          />
        ) : (
          <UserTableActions
            status={rowData.status}
            isProband={!!rowData.proBandId}
            onViewClick={(e) => onViewClick(e, rowData)}
            onViewPatientClick={(e) => onViewPatientClick(e, rowData)}
            getXMLData={(e) => getXMLData(e, rowData)}
            onDeleteClick={(e) => openDeleteModal(e, rowData)}
            getJsonData={(e) => getJsonData(e, rowData)}
            markAsImported={(e) => markAsImported(e, rowData)}
          />
        )
    }
  ];

  const boxColumns = [
    { title: 'UPN', field: 'upn', width: 10, sorting: false },
    {
      title: t('Relationship'),
      render: (rowData) => relationshipMap[rowData.relationship] ?? ''
    },
    { title: t('First Name'), field: 'firstName' },
    { title: t('Last Name'), field: 'lastName' },
    {
      title: t('Date of Birth'),
      field: 'dob',
      render: (rowData) => parseDate(rowData.dob)
    },
    {
      title: t('Date of Death'),
      field: 'dod',
      render: (rowData) => parseDate(rowData.dod)
    },
    {
      title: t('Diagnoses (Age)'),
      sorting: false,
      cellStyle: { padding: '0 0 0 5px' },
      render: (rowData) => {
        return rowData.diagnoses?.map((el, i) => (
          <Typography variant="body2" key={i}>
            {diagnosesMap[el]}
            {rowData.diagnosesAge &&
              rowData.diagnosesAge[el] &&
              ` (${rowData.diagnosesAge[el]})`}
          </Typography>
        ));
      }
    },
    { title: t('Comments'), field: 'comments', sorting: false },
    {
      title: t('Actions'),
      sorting: false,
      cellStyle: { padding: '0 0 0 5px' },
      render: (rowData) => (
        <>
          {rowData.disableEdit ? (
            <CustomIconBtn
              icon={<Visibility fontSize="small" />}
              disabled={true}
            />
          ) : (
            <CustomIconBtn
              type="view"
              tooltip="View / Edit"
              onClick={(e) => onViewClick(e, rowData, true)}
              disabled={rowData.disableEdit}
            />
          )}
          <CustomIconBtn
            type="delete"
            onClick={(e) => openDeleteModal(e, rowData)}
          />
        </>
      )
    }
  ];
  return (
    <Table
      title=""
      rows={probandUsers}
      columns={columns}
      pageSize={8}
      dense={true}
      isCollapsible={true}
      boxColumns={
        patient ? boxColumns.filter((t) => t.title !== 'Actions') : boxColumns
      }
      onRowClick={getFamilyData}
      tableRef={tableRef}
      highlightOnSelect={true}
      selectedTableDataId={previousSelectedId}
      highlightColor="#9ef1ff"
      hoverColor="#f3f3f3"
      // shouldToggle={true}
      previousSelectedId={previousSelectedId}
      // setPreviousSelectedId={setPreviousSelectedId}
    />
  );
}
