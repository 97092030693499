import React from 'react';

import {
  MenuList,
  MenuItem,
  Chip,
  Modal,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
  FormLabel
} from '@mui/material';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { getPos, set_Cache } from './PedigreeChart/cache';
import { CONSTANTS } from '../Constants';
import { CONSTANTS as c } from 'src/components/questionnaire/Constants';
import { diagnosesMap } from 'src/components/questionnaire/Constants';
import { diagnsesShapeMap } from './pedigreeUtil';
import { useAutoTranslation } from 'src/hooks/useTranslate';

import { AppContext, ThemeContext } from 'src/store/ContextStore';

import * as d3 from 'd3';
import TextInput from '../UI/input/TextInput';

export default function DiagnosisModal({
  deepCopy,
  setDeepCopy,
  setDataset,
  setPedigreeState,
  diagnoses
}) {
  const { theme } = React.useContext(ThemeContext);

  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user, family } = users;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currentDiagnosis, setCurrentDiagnosis] = React.useState('none');
  const [diagnosisAge, setDiagnosisAge] = React.useState('');
  let draggableData = [];

  let hold = React.useRef(deepCopy);
  let chosen = React.useRef(-1);

  const replacer = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (key === 'parent_node') {
        return undefined;
      }
    }
    return value;
  };

  const updateData = (dataset) => {
    set_Cache(JSON.stringify(dataset, replacer), user?.familyId);
    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset, replacer));
    });
    setDataset(dataset);
  };

  const handleSubmit = async (index, diagnosis, age, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (index == -1) return;
    const updateChangedNodes = [];
    if (!deepCopy) deepCopy = hold.current;
    const node = deepCopy.filter((p, i) => i === index)[0];
    let insert = node.diagnoses;
    insert.push(diagnosis);
    insert = [...new Set(insert)];

    const updatedNode = {
      ...node,
      diagnoses: insert,
      diagnosesAge: { ...node.diagnosesAge, [diagnosis]: age }
    };

    updateChangedNodes.push(updatedNode);

    const newDataset = deepCopy.map((person) => {
      const matchedNode = updateChangedNodes.find(
        (node) => node.pid === person.pid
      );
      if (matchedNode) {
        return { ...person, ...matchedNode };
      }
      return person;
    });
    setDataset([...newDataset]);
    updateData([...newDataset]);
    setModalOpen(false);
    setDiagnosisAge('');
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return '.11em';
      })
      .style('stroke', 'black');
  };

  const distance = (point1, point2) => {
    return Math.sqrt(
      Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
    );
  };

  function dragstarted(d) {
    d3.select(this).style('opacity', 1);
    setCurrentDiagnosis(d.subject.diagnosis);
  }

  function dragged(d) {
    var xy = d3.pointer(d.sourceEvent);

    let mousePos = { x: xy[0], y: xy[1] };

    let choice = { index: 0, dist: 1000 };
    const members = document.getElementsByClassName('node');

    for (let i = 0; i < members.length; i++) {
      let ctm = members[i].getScreenCTM();
      let nodePos = { x: ctm.e, y: ctm.f };
      let dist = distance(mousePos, nodePos);
      if (dist < choice.dist) choice = { index: i, dist };
    }

    var nodeOutlines = d3.selectAll('.te');

    nodeOutlines
      .style('stroke-width', function (d, i) {
        return i === choice.index ? '.25em' : '.11em';
      })
      .style('stroke', function (d, i) {
        return i === choice.index ? theme.accent : 'black';
      });

    d3.select(this)
      .datum(function (a) {
        return {
          id: d.subject.id,
          diagnosis: d.subject.diagnosis,
          x: d.x - 10,
          y: d.y + 5
        };
      })
      .attr('transform', (d) => `translate(${d.x},${d.y})`);
  }

  function dragended(d) {
    var dragged = d3.select(this);

    var xy = d3.pointer(d.sourceEvent);

    let mousePos = { x: xy[0], y: xy[1] };

    let choice = { index: 0, dist: 1000 };
    const members = document.getElementsByClassName('node');

    for (let i = 0; i < members.length; i++) {
      let ctm = members[i].getScreenCTM();
      let nodePos = { x: ctm.e, y: ctm.f };
      let dist = distance(mousePos, nodePos);
      if (dist < choice.dist) choice = { index: i, dist };
    }

    dragged
      .datum(function (a) {
        return {
          id: d.subject.id,
          diagnosis: d.subject.diagnosis,
          x: 0,
          y: draggableData[d.subject.id].y
        };
      })
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .style('opacity', 0);
    if (choice.dist < 250) {
      if (
        d.subject.diagnosis !== 'other' &&
        !c.pregnanciesDiagnoses.includes(d.subject.diagnosis)
      ) {
        setCurrentDiagnosis(d.subject.diagnosis);
        chosen.current = choice.index;
        setModalOpen(true);
      } else {
        d3.selectAll('.te')
          .style('stroke-width', function (d, i) {
            return '.11em';
          })
          .style('stroke', 'black');
        handleSubmit(choice.index, d.subject.diagnosis, diagnosisAge);
      }
    }
  }
  const t = useAutoTranslation();

  React.useEffect(() => {
    var diagnosisModal = d3.select('.diagnosisModal');

    let svg = diagnosisModal.select('svg');

    for (let i = 0; i < diagnoses.length; i++) {
      draggableData.push({
        id: i,
        x: -10,
        y: 35 * i - 4,
        diagnosis: diagnoses[i],
        width: 300
      });
    }

    let text = svg
      .selectAll('.dragon')
      .data(draggableData)
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .attr('opacity', 0)
      .call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );

    text
      .append('rect')
      .attr('z-index', 999999)
      .attr('height', 24)
      .attr('width', 300)
      .attr('rx', 15)
      .style('border', 'grey 1px solid')
      .attr('fill', 'white')
      .raise();

    text
      .append('text')
      .text((d) => t(diagnosesMap[d.diagnosis]))
      .attr('y', 15)
      .attr('x', 25)
      .attr('font-family', `"Roboto", "Helvetica", "Arial", sans-serif`)
      .style('font-size', '1rem')
      .style('color', 'white');

    text.each(function (e, i) {
      var bbox = d3.select(this).select('text').node().getBBox();
      var width = bbox.width;
      d3.select(this)
        .select('rect')
        .attr('width', width + 50);
    });
  }, [diagnoses]);

  React.useEffect(() => {
    if (deepCopy) hold.current = deepCopy;
  });

  const handleCloseModal = () => {
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return '.11em';
      })
      .style('stroke', 'black');
    setModalOpen(false);
  };
  return (
    <div className="diagnosisModal" style={{ position: 'absolute' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
        overflow="visible"
      />
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomDialog
          maxWidth={'sm'}
          title={`Age ${diagnosesMap[currentDiagnosis]} Diagnosed`}
          open={modalOpen}
          handleClose={handleCloseModal}
          minHeight={'auto'}
          onClose={true}
        >
          <form className="user-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setDiagnosisAge(e.target.value)}
                  name={currentDiagnosis}
                  value={diagnosisAge}
                />
              </Grid>
              <ModalButtons
                hideContinueAdding={true}
                handleClose={handleCloseModal}
                onClick={async (e) =>
                  handleSubmit(
                    chosen.current,
                    currentDiagnosis,
                    diagnosisAge,
                    e
                  )
                }
                addLabel={`Save`}
              />
            </Grid>
          </form>
        </CustomDialog>
      </Modal>
    </div>
  );
}
